import {
  ErrorBoundary,
  Facet,
  Paging,
  PagingInfo,
  ResultsPerPage,
  SearchBox,
  SearchProvider,
  Sorting,
  WithSearch
} from "@elastic/react-search-ui";
import { Layout } from "@elastic/react-search-ui-views";
import "@elastic/react-search-ui-views/lib/styles/styles.css";
import AppSearchAPIConnector from "@elastic/search-ui-app-search-connector";
import { Card, Spin, Button } from "antd";
import React from "react";
import {
  buildAutocompleteQueryConfig,
  buildFacetConfigFromConfig,
  buildSearchOptionsFromConfig,
  buildSortOptionsFromConfig,
  getConfig,
  getFacetFields
} from "./config/config-helper";
import Item from "./Item";
import "./styles/additional.css";
import "./styles/Dashboard.css";
import { useState } from 'react';

import { saveAs } from 'file-saver';
import { utils, write } from 'xlsx';

const MAX_EXCEL_CELL_LENGTH = 32000;


const flattenObject = (obj, prefix = '') => {
  return Object.keys(obj).reduce((acc, key) => {
    const pre = prefix.length ? prefix + '.' : '';
    if (typeof obj[key] === 'object' && obj[key] !== null && !Array.isArray(obj[key]) && Object.prototype.toString.call(obj[key]) !== '[object Date]') {
      Object.assign(acc, flattenObject(obj[key], pre + key));
    } else {
      if (typeof obj[key] === 'string' && obj[key].length > MAX_EXCEL_CELL_LENGTH) {
        acc[pre + key] = obj[key].replace(/d@mg\.glas\.vin/g, '').substring(0, MAX_EXCEL_CELL_LENGTH) + '...'; // Truncate and append ellipsis
      } else {
        acc[pre + key] = obj[key];
      }
    }
    return acc;
  }, {});
};


const exportToExcel = (input, filename = 'export.xlsx') => {
  console.log(input)

  const data = input.map(x=>({
    "date.raw": x["date.raw"],
    "subject.raw": x["subject.raw"],
    "body_plain.raw": x["body_plain.raw"],
    "from.raw": x["from.raw"],
  }))

  console.log(data)

  
    // Convert JSON to worksheet
    const worksheet = utils.json_to_sheet(data);

    // Create a new workbook
    const workbook = utils.book_new();

    // Append the worksheet to the workbook
    utils.book_append_sheet(workbook, worksheet, 'Sheet1');

    // Write the workbook and convert it to Blob object
    const excelBuffer = write(workbook, { bookType: 'xlsx', type: 'array' });
    const dataBlob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });

    // Use FileSaver to save the file
    saveAs(dataBlob, filename);
};

const { Meta } = Card;

const { hostIdentifier, searchKey, endpointBase, engineName } = getConfig();
const connector = new AppSearchAPIConnector({
  searchKey,
  engineName,
  hostIdentifier,
  endpointBase
});
const config = {
  searchQuery: {
    facets: buildFacetConfigFromConfig(),
    ...buildSearchOptionsFromConfig()
  },
  autocompleteQuery: buildAutocompleteQueryConfig(),
  apiConnector: connector,
  alwaysSearchOnInitialLoad: true
};


function CustomResultsPerPage({ options, value, onChange }) {
  const resultsPerPageOptions = [...options, 100, 500]; // Add 100 to the existing options.

  return (
    <div>
      Results per page:
      <select
        value={value}
        onChange={(e) => onChange(parseInt(e.target.value))}
        className="sui-select__control"
      >
        {resultsPerPageOptions.map((option) => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </select>
    </div>
  );
}

function DateFilter() {
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  return (
    <WithSearch mapContextToProps={({ addFilter, setFilter }) => ({ addFilter, setFilter })}>
      {({ addFilter, setFilter }) => {
        const applyFilter = () => {
          addFilter("timestamp",{
            name: "timestamp",
            from: startDate ? Date.parse(startDate)/1000 : 0,
            to:   endDate ? Date.parse(endDate)/1000 + 86400 : 99999999999999999,
          })
          setFilter("timestamp",{
            name: "timestamp",
            from: startDate ? Date.parse(startDate)/1000 : 0,
            to:   endDate ? Date.parse(endDate)/1000 + 86400 : 99999999999999999,
          })
        };

        return (
          <div>
            <br/>
            <legend class="sui-facet__title">Date Filter</legend>
            <label>
              Start Date:
              <input className="sui-facet-search__text-input" type="date" value={startDate} onChange={(e) => setStartDate(e.target.value)} />
            </label>
            <br/>
            <label>
              End Date:
              <input className="sui-facet-search__text-input" type="date" value={endDate} onChange={(e) => setEndDate(e.target.value)} />
            </label>
            <br/>
            <Button onClick={applyFilter}>Apply Date Filter</Button>
          </div>
        );
      }}
    </WithSearch>
  );
}


export default function App() {



  var wasSearched = true;
  return (
    <SearchProvider config={config}>
      <WithSearch mapContextToProps={({ results, isLoading }) => ({ results, isLoading })}>
        {({ results, isLoading }) => {
          console.log("isLoading: " + isLoading);
          return (
            <div
              className="App"
              id="reactAppDiv"
            // style={{ visibility: "hidden" }}
            >
              <ErrorBoundary>
                <Layout
                  header={<SearchBox inputView={({ getAutocomplete, getInputProps, getButtonProps }) => (
                    <>
                      <div className="sui-search-box__wrapper">
                        <input
                          {...getInputProps({
                            placeholder: "Type here to search!"
                          })}
                        />
                        {getAutocomplete()}
                      </div>
                      <Spin spinning={isLoading}>
                        <input
                          {...getButtonProps({
                            value: (isLoading ? "Search" : "Search"),
                          })}
                        />
                      </Spin>

                    </>
                  )} autocompleteSuggestions={true} />}
                  sideContent={
                    <div>
                      {wasSearched && (
                        <Sorting
                          label={"Sort by"}
                          sortOptions={buildSortOptionsFromConfig()}
                        />
                      )}

                      {getFacetFields().map((field) => (
                        <Facet // where all the filters are stored
                          key={field}
                          field={field}
                          label={field}
                          filterType="any"
                          isFilterable={true}


                        />
                      ))}

                      <DateFilter />
                    </div>
                  }
                  // the results are stored here\

                  bodyContent={
                    //

                    <div className="App">
                      <br />

                      

                      {results.map((r) => (
                        <ErrorBoundary>
                          <Item r={r} />
                        </ErrorBoundary>
                      ))}
                      <Button onClick={() => exportToExcel(results.map(obj => flattenObject(obj, '')), 'export.xlsx')}>Export to Excel</Button>
                    </div>
                  }
                  // Result header (e.g. "showing 1-20 out of 1037")
                  bodyHeader={
                    <React.Fragment>
                      {wasSearched && <PagingInfo />}
                      
                      {wasSearched && <ResultsPerPage view={CustomResultsPerPage} />}
                    </React.Fragment>
                  }
                  bodyFooter={<Paging />}
                />
              </ErrorBoundary>
            </div>
          );
        }}
      </WithSearch>
    </SearchProvider>
  );
}
